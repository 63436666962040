.home-board {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;

  & > title {
    display: flex;
    gap: 0.5em;
    justify-content: center;
    align-items: flex-end;
  }

  & > header {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 1em;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;
    }
  }

  & > main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1em;
    position: relative;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      li {
        display: flex;
        width: fit-content;
      }
    }

    & > .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 50;
    }
  }
}
