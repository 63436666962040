.game {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  & > main {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 2em 3em 2em;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    gap: 2em;

    .toolbar {
      display: flex;
      flex-direction: column;
      gap: 1em;
      align-items: center;
      color: lightgray;
      font-style: italic;
      min-height: 230px;

      & > div {
        display: flex;
        gap: 1em;
        align-items: center;
      }

      & > ul {
        display: flex;
        flex-direction: column;
        gap: 1em;
        text-align: center;
        max-width: 21em;
      }
    }

    & > .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      background-color: rgba($color: #000000, $alpha: 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: x-large;
    }
  }

  & > aside {
    z-index: 90;
    right: 0;
    height: 100%;
    background-color: #10101f;
    display: flex;
    align-items: stretch;

    & > button {
      border-radius: 0;
      border: none;
      padding: 3px;
      font-family: 'Courier New', Courier, monospace;
    }

    & > div {
      overflow: hidden;
      min-width: 170px;
    }
  }

  & > footer {
    z-index: 70;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

@media (max-height: 780px) {
  .game main {
    gap: 0;
  }
}
