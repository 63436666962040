* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Inter;
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
}

body {
  color: #fff;
  background: #17141f;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

button,
input {
  padding: 0.5em;
}
