.settings {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  & > h1 {
    text-align: center;
  }

  & > button {
    width: 100%;
  }
}
